<template>
	<div class="taskEnterWrap">
		<div class="taskEnter">
			<div class="taskItem" v-for="(item,index) in taskList" :key="index">
				<div class="taskIcon">
					<i class="iconfont" :class="item.icon"></i>
				</div>
				<div class="title">
					{{item.title}}
				</div>
				<div class="content">
					{{item.content}}
				</div>
				<div class="but hoverHand"  @click="goTaskone(index)">
					{{item.butText}}
				</div>
			</div>
		</div>
		<div class="course">
			<img src="../../assets/sysImage/task.png" alt="教程">
			<div class="courseWrap">
				<div class="courseItem" v-for="(item,index) in courseList" :key="index">
					<div class="title">
						<span class='shu'></span>
						<span>{{item.name}}</span>
					</div>
					<div class="content">
						{{item.content}}
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				courseList: [{
						name: '二维码任务',
						content: '通过提供二维码入店找到宝贝进行下单，同时支持自动生成猜你喜欢、卡首屏二维码',
					},
					{
						name: '淘口令任务',
						content: '通过提供淘口令入店找到宝贝进行下单',
					},
					{
						name: '回访隔日单任务',
						content: '自定义访客隔日或几日后回访下单，增加回购权重',
					},
					{
						name: '叠词回访任务',
						content: '买家切换两个不同的搜索关键词进店货比，收藏加购浏览下单',
					},
					{
						name: '竞品流量劫持任务',
						content: '对竞品进行浏览、拍下不付款等行为,最终选择自己的产品下单，让系统判定自己产品更具有优势，蚕食竞品的流量',
					},
					{
						name: '淘口令列表任务',
						content: '通过将一个淘口令裂变多个给多个买家进行分享购买',
					},
					{
						name: '活动单任务',
						content: '以淘宝为例报名聚划算、淘抢购等活动，可填写活动入口说明进店也可直接给出二维码进店',

					},
					{
						name: '抖音跳转任务',
						content: '从抖音跳转到电商平台进行下单,提升抖音新媒体站外渠道入口比重，增加进店入口多样性',

					},
					{
						name: '抖音小店任务',
						content: '从抖音跳转到电商平台进行下单,提升抖音新媒体站外渠道入口比重，增加进店入口多样性',
					
					},
				],
				taskList: [{
						icon: 'icon-qian',
						title: '垫付任务',
						content: '完成每日补单，有效提升宝贝销量与权重',
						butText: '发布垫付任务',
					},
					{
						icon: 'icon-huojian',
						title: '超级高权重任务',
						content: '通过不同维度不同进店途径模拟真实买家购物，精准提升权重',
						butText: '发布高权重任务',
					}, {
						icon: 'icon-hot',
						title: '人气浏览任务',
						content: '提升店铺真实浏览访客，提升店铺人气',
						butText: '发布人气任务'
					}, {
						icon: 'icon-radioguangbo',
						title: '垫付任务',
						content: '完成每日补单，有效提升宝贝销量与权重',
						butText: '发布自媒体任务'
					}, {
						icon: 'icon-pingjia',
						title: '垫付任务',
						content: '完成每日补单，有效提升宝贝销量与权重',
						butText: '发布评价任务'
					}
				]
			}
		},
		methods: {
			goTaskone(){
				console.log(this.$route.path)
				if(this.$route.path !== '/pageIndex/taskone'){
					this.$router.push({path:'/pageIndex/taskone'})
				}
			}
		},
		components: {
			
		},
		watch: {
			// data(newValue, oldValue) {

			// }
		},
		filters: {
			// fnName: function(value) {
			// 	return value;
			// }
		},
		beforeCreate() {

		},
		created() {

		},
		beforeMount() {

		},
		mounted() {

		},
		beforeUpdate() {

		},
		updated() {

		},
		beforeDestroy() {

		},
		destroyed() {

		},
		computed: {
			// name() {
			// 	return this.data
			// }
		},

	}
</script>

<style lang="less" scoped>
	.course{
		background-color: white;
		img{
			width: 100%;
			cursor: pointer;
		}
	}
	.courseWrap {
		padding: 20px;
		display: flex;
		justify-content: flex-start;
		flex-wrap: wrap;
		
		.courseItem{
			cursor: pointer;
			margin-left: 20px;
			margin-bottom: 20px;
			width: 285px;
			height: 176px;
			padding: 20px 40px;
			border-radius: 10px;
			box-shadow: 0 0 15px #ddd;
			box-sizing: border-box;
			.title {
				width: 100%;
				display: flex;
				font-size: 20px;
				span {
					display: inline-block;
				}
				.shu{
					width: 4px;
					height: 15px;
					background-color: @primaryColorQian;
					margin-right: 8px;
					margin-bottom: 10px;
					font-size: 20px;
				}
				
			}
			.content{
				
				color: #999999;
				font-size: 14px;
			}
		}
	}

	.taskItem:hover {
		box-shadow: 0 0 20px #eee;
	}

	.taskItem {
		border: 1px solid #eee;
		box-sizing: border-box;
		width: 220px;
		padding-top: 20px;
		margin: 0 0 20px 20px;
		border-radius: 5px;

		.but {
			width: 142px;
			height: 42px;
			line-height: 42px;
			border-radius: 21px;
			background-color: #fff1ed;
			border: 1px solid #ffbdaa;
			border-radius: 23px;
			color: @primaryColorQian;
			font-weight: 400;
			font-size: 16px;
			text-align: center;
			margin: 0 auto 20px;
		}

		.but:hover {
			background-color: @primaryColorQian;
			border: 1px solid @primaryColorQian;
			color: white;
		}

		.title {
			font-size: 18px;
			text-align: center;
			margin-bottom: 10px;

		}

		.content {
			padding: 0 20px;
			box-sizing: border-box;
			font-size: 12px;
			height: 70px;
		}

		.taskIcon {
			text-align: center;
			color: #222;
			margin-bottom: 20px;

			i {
				font-size: 45px;
				margin-bottom: 20px;
			}
		}
	}

	.taskEnter {
		display: flex;
		justify-content: flex-start;
		flex-wrap: wrap;
		background-color: white;
		margin-bottom: 20px;
		padding: 20px 0 0;
	}

	.taskEnterWrap {
		width: 980px;
		
	}
</style>
